<template>
  <section class="sponsors">
    <div class="sponsors__content">
      <h1 class="sponsors__title">
          Organizers & Partners
      </h1>
      <hr class="sponsors__line">
      <h2 class="sponsors__subtitle">
        Host
      </h2>
      <div class="sponsors__logos">
        <template v-for="host in hosts" :key="host.name">
          <a v-if="host.url" :href="host.url" target="_blank">
            <div class="sponsors__item single">
              <div class="sponsors__logo-container nobg">
                <img :src="host.logo" :alt="host.name" :class="host.class || 'logo'">
              </div>
            </div>
          </a>
          <div v-else class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img :src="host.logo" :alt="host.name" :class="host.class || 'logo'">
            </div>
          </div>
        </template>
      </div>
      <h2 class="sponsors__subtitle">
        Support
      </h2>
      <div class="sponsors__logos">
        <template v-for="supporter in support" :key="supporter.name">
          <a v-if="supporter.url" :href="supporter.url" target="_blank">
            <div class="sponsors__item single">
              <div class="sponsors__logo-container nobg">
                <img :src="supporter.logo" :alt="supporter.name" :class="supporter.class || 'full-logo'">
              </div>
            </div>
          </a>
          <div v-else class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img :src="supporter.logo" :alt="supporter.name" :class="supporter.class || 'full-logo'">
            </div>
          </div>
        </template>
      </div>
      <h2 class="sponsors__subtitle">
        Media & Cooperation Support
      </h2>
      <div class="sponsors__logos">
        <template v-for="media in mediaSupport" :key="media.name">
          <a v-if="media.url" :href="media.url" target="_blank">
            <div class="sponsors__item single">
              <div class="sponsors__logo-container nobg">
                <img :src="media.logo" :alt="media.name" :class="media.class || 'full-logo'">
              </div>
            </div>
          </a>
          <div v-else class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img :src="media.logo" :alt="media.name" :class="media.class || 'full-logo'">
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@/styles/components/sponsors.scss';
</style>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const hosts = ref([
      { logo: require('@/assets/sponsors/solowin.png'), url: 'https://ir.solomonwin.com.hk/', class: 'logo' },
      { logo: require('@/assets/sponsors/sequire.svg'), url: 'https://srax.com/', class: 'full-logo' },
    ]);

    const support = ref([
      { logo: require('@/assets/sponsors/IHK.png'), class: 'full-logo' },
      { logo: require('@/assets/sponsors/ChinaAMC.png'), class: 'full-logo' },
      { logo: require('@/assets/sponsors/cambria.png'), class: 'full-logo' },
      { logo: require('@/assets/sponsors/OSL.png'), class: 'logo' },
      { logo: require('@/assets/sponsors/spw.png'), class: 'full-logo' },
      { logo: require('@/assets/sponsors/solomon.png'), class: 'full-logo' },
    ]);

    const mediaSupport = ref([
      { logo: require('@/assets/sponsors/afip.png'), class: 'full-logo' },
      { logo: require('@/assets/sponsors/bevilacqua.png'), class: 'full-logo' },
      { logo: require('@/assets/sponsors/Christensen.png'), class: 'full-logo' },
      { logo: require('@/assets/sponsors/cpic.png'), class: 'full-logo' },
      { logo: require('@/assets/sponsors/famofi.png'), class: 'logo' },
      { logo: require('@/assets/sponsors/fern-win.png'), class: 'full-logo' },
      { logo: require('@/assets/sponsors/garvis.png'), class: 'logo' },
      { logo: require('@/assets/sponsors/GBAFOA.png'), class: 'full-logo' },
      { logo: require('@/assets/sponsors/gofintech.png'), class: 'full-logo' },
      { logo: require('@/assets/sponsors/HKIBFA-logo.png'), class: 'full-logo' },
      { logo: require('@/assets/sponsors/HKIFOA-logo.png'), class: 'full-logo' },
      { logo: require('@/assets/sponsors/hongkong-lpf.png'), class: 'full-logo' },
      { logo: require('@/assets/sponsors/maicapital.png'), class: 'logo' },
      { logo: require('@/assets/sponsors/microcaps.png'), class: 'full-logo' },
      { logo: require('@/assets/sponsors/PR1.png'), class: 'logo' },
      { logo: require('@/assets/sponsors/qreg.png'), class: 'logo' },
      { logo: require('@/assets/sponsors/verimedia-logo.png'), class: 'logo' },
      { logo: require('@/assets/sponsors/wealthking-inv.png'), class: 'full-logo' }
    ]);

    return {
      hosts,
      support,
      mediaSupport,
    };
  },
};
</script>