<template>
  <div class="agenda__content container">
    <h2 class="subtitle">
      Afternoon Sessions
    </h2>
    <div class="cards__container">
      <div class="cards__single gray-darker noheight">
        <p class="cards__title">
          Welcome
        </p>
        <p class="cards__time">
          1:00 PM - 1:15 PM
        </p>
        <div class="cards__description">
          <p>
            Hosts: Chris Miglino CEO, Founder of SRAX & Thomas Tam, CEO of Solowin Holdings
          </p>
        </div>
      </div>
      <div class="cards__single cerulean mrjoseph">
        <div class="circle">
          <div class="speaker-img chan"></div>
        </div>
        <div class="cards__speaker">
          <p class="cards__name">
            Mr Joseph H. L. Chan, JP
          </p>
          <div class="cards__description">
            <p class="description">
              Under Secretary for Financial Services and the Treasury<br> The Government of Hong Kong SAR
            </p>
          </div>
          <p class="cards__spktitle">
            1:15 PM - 1:20 PM
          </p>
        </div>
      </div>
      <div class="cards__single cerulean">
        <div class="circle">
          <div class="speaker-img najarian"></div>
        </div>
        <div class="cards__speaker">
          <p class="cards__name">
            Keynote - Jon Najarian
          </p>
          <p class="cards__spktitle">
            1:20 PM - 2:20 PM
          </p>
        </div>
        <div class="cards__description">
          <p class="description">
            Current trends and challenges in the financial industry with Market Rebellion's Co-Founder, Jon Najarian
          </p>
        </div>
      </div>
      <div class="cards__single grayblue noheight">
        <p class="cards__title">
          Panel Discussion - Asian Based Companies Listing on Hong Kong and US Exchanges
        </p>
        <p class="cards__time">
          2:20 PM - 3:00 PM
        </p>
        <div class="cards__description">
          <div v-if="panels.panel1">
            <p>
              <b class="title">Moderator:</b>
              <b>Chris Miglino</b> - CEO & Co-Founder of SRAX<br>
              <br>
              <b class="title">Panelists: </b>
              <b>Fred Ting</b> - Responsible Officer of Solomon JFZ (Asia) Holding Limited<br>
              <b>Gordon McBean</b> - Chairman and Chief Executive Officer of Cambria Capital, LLC<br>
              <b>Joel Vanderhoof</b> - President of Cambria Capital, LLC<br>
              <b>Kevin (Qixiang) Sun</b> - Partner of Bevilacqua PLLC
            </p>
            <br>
            <p>
              Explore the rising trend of Asian-based companies seeking listings on Hong Kong and US exchanges. Gain insights into motivations, regulatory considerations, and the impact on investors and corporations. Join us to understand how this trend is reshaping international capital markets
            </p>
          </div>
          <a class="show-btn" @click="toggleDescription('panel1')">
            {{ panels.panel1 ? 'Show Less' : 'Show More' }}
          </a>
        </div>
      </div>
      <div class="cards__single cerulean minheight">
        <div class="circle">
          <div class="speaker-img mcbean"></div>
        </div>
        <div class="cards__speaker">
          <p class="cards__name">
            New Financial Solution Unveiling Session with Gordon McBean, CEO of Cambria Capital
          </p>
          <p class="cards__spktitle">
            3:00 PM - 3:15 PM
          </p>
        </div>
      </div>
      <div class="cards__single grayblue noheight">
        <p class="cards__title">
          Panel Discussion - The Future of Fintech in Asia and Relevant Regulatory Compliance and Governance for Financial Institutions
        </p>
        <p class="cards__time">
          3:15 PM - 4:00 PM
        </p>
        <div class="cards__description">
          <div v-if="panels.panel2">
            <p>
              <b class="title">Moderator:</b>
              <b>Poseidon Ho</b> - Founding Partner of Outliers Fund IV, a DNA Fund <br>
              <br>
              <b class="title">Panelists: </b>
              <b>Clara Chiu</b> - CEO of QREG Advisory Former Fintech Head & Licensing Director of HK SFC<br>
              <b>CG Zhou</b> - CEO of CPIC Investment Management (H.K.) Company Ltd<br>
              <b>Gary Tiu</b> - Executive Director and Head of Regulatory Affairs of OSL Group<br>
              <b>Marco Lim</b> - Managing Partner of MaiCapital
            </p>
            <br>
            <p>
              Fintech leaders and experts discussing innovations and trends shaping the fnancial landscape in Asia Opportunities and challenges in fntech adoption. Regulatory experts discussing compliance challenges and best practices in the fnancial sector Strategies for navigating regulatory changes
            </p>
          </div>
          <a class="show-btn" @click="toggleDescription('panel2')">
            {{ panels.panel2 ? 'Show Less' : 'Show More' }}
          </a>
        </div>
      </div>
      <div class="cards__single cerulean noheight">
        <div class="circle">
          <div class="speaker-img pierce"></div>
        </div>
        <div class="cards__speaker">
          <p class="cards__name">
            Keynote - Brock Pierce
          </p>
          <p class="cards__spktitle">
            4:00 PM - 5:00 PM
          </p>
        </div>
        <div class="cards__description">
          <p class="description">
            Join us for a captivating session with Brock Pierce, Chairman of Bitcoin Foundation as he shares his insights on the latest trends in the crypto market. Gain valuable perspectives on emerging opportunities, regulatory landscapes, and the future of digital currencies. Don't miss this chance to delve into the forefront of
            blockchain innovation, right here in Hong Kong
          </p>
        </div>
      </div>
      <div class="cards__single red noheight">
        <div class="row">
          <p class="cards__title">
            Closing Remarks
          </p>
          <p class="cards__time">
            5:00 PM - 5:30 PM
          </p>
        </div>
        <div class="cards__description">
          <p>
            Hosts: Chris Miglino CEO, Founder of SRAX & Thomas Tam, CEO of SOLOWIN
          </p>
        </div>
      </div>
      <div class="cards__single green noheight">
        <div class="row">
          <p class="cards__title">
            Happy Hour / Dinner @ Joia Ristorante Italiano
          </p>
          <p class="cards__time">
            5:30 PM - 7:30 PM
          </p>
        </div>
        <div class="cards__description">
          <p>
            <a href="https://maps.app.goo.gl/N2w5BtT8wGXkXbR77" target="_blank">
              Shop R010, Level 3, Elements, 1 Austin Road W, Tsim Sha Tsui
            </a> <br>
            <a href="tel:+85223822323">+852 2382 2323</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '@/styles/pages/agenda.scss';
</style>

<script setup>
import { reactive } from 'vue';

const panels = reactive({
  panel1: false,
  panel2: false
});

const toggleDescription = (panelKey) => {
  panels[panelKey] = !panels[panelKey];
};
</script>

