<template>
  <section class="services">
    <div class="services__butler">
      <div class="services__content container">
        <div class="services__content--image">
          <img src="@/assets/venue/carousel/restaurant.png" alt="Restaurant">
        </div>
        <div class="services__content--info">
          <h1 class="services__title">
            Exquisite Sky-High Dining in Hong Kong
          </h1>
          <p class="services__description">
            Experience the ultimate dining pleasure at our many exceptional restaurants
            from 102/F and beyond, including two MICHELIN-starred Tin Lung Heen and one
            Michelin-starred Tosca di Angelo.
          </p>
          <a href="https://www.ritzcarlton.com/en/hotels/hkgkw-the-ritz-carlton-hong-kong/overview/?nst=paid&cid=PAI_GLB00050K5_GLE000C53T_GLF000QGQD&ppc=ppc&pId=corpimedia&gad_source=1&gclid=Cj0KCQjw2uiwBhCXARIsACMvIU1OuWNOFFblCukHb546gn-I6sOyPTgaYzp4u2w4_JbxV58F0yqUDZoaAqkdEALw_wcB&gclsrc=aw.ds"
              target="_blank" class="services__btn btn">
            <button class="button">Learn More</button>
          </a>
        </div>
      </div>
    </div>
    <div class="services__spa">
      <div class="services__content container">
        <div class="services__content--image">
          <img src="@/assets/venue/carousel/conference.png" alt="Conference Room">
        </div>
        <div class="services__content--info">
          <h1 class="services__title">
            Meeting Rooms Perched Above Hong Kong
          </h1>
          <p class="services__description">
            The Ritz-Carlton, Hong Kong is a meeting destination with more than 17,000 sq. ft.
            of event space and services to accommodate any type of gathering. Venues are the
            foundation of the conference experience, but the services are what elevate it.
            Dedicated planners, event concierges, audiovisual experts and a talented culinary
            team assist with customizing every meeting with details large and small.
          </p>
          <a href="https://www.ritzcarlton.com/en/hotels/hkgkw-the-ritz-carlton-hong-kong/overview/?nst=paid&cid=PAI_GLB00050K5_GLE000C53T_GLF000QGQD&ppc=ppc&pId=corpimedia&gad_source=1&gclid=Cj0KCQjw2uiwBhCXARIsACMvIU1OuWNOFFblCukHb546gn-I6sOyPTgaYzp4u2w4_JbxV58F0yqUDZoaAqkdEALw_wcB&gclsrc=aw.ds"
              target="_blank" class="services__btn btn">
            <button class="button">Learn More</button>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>


<script>

export default {
  name: 'Services',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/components/venue/services.scss";
</style>