import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import 'vue-social-sharing';

createApp(App).use(router)
    .use(router)
    .mount('#app')
