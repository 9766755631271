<template>
  <section class="hero">
      <div class="hero__content container">
        <div class="hero__content--info">
          <h1 class="hero__title">
            Asia Investor Summit<br>
            By Solowin & Sequire
          </h1>
          <h2 class="hero__date">
            May 29th, 2024
          </h2>
          <h3 class="hero__location">
            Diamond Ballroom, The Ritz-Carlton, ICC, Hong Kong
          </h3>
        </div>
      </div>
    </section>
  </template>
  
  <script>

  export default {
    name: 'Hero',
    data () {
      return {
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  @import "../styles/components/hero.scss";
  </style>