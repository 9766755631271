<template>
  <div class="links">
    <a href="https://asiainvestorsummit.com/" target="_blank">
      <img src="../assets/solowin-logo.png" class="logo" alt="Logo">
    </a>
    <div class="links__content container">
      <h1>Join the live financial event now for expert insights and real-time updates.</h1>
      <i class="fa-solid fa-chevron-down"></i>
      <div class="buttons">
        <a href="https://asiainvestorsummit.com/agenda" target="_blank" class="button">AGENDA</a>
        <a href="https://live.photoplus.cn/live/pc/12300858/#/live" target="_blank" class="button">PICTURES</a>
        <a href="https://youtube.com/live/388ilytTUdM?feature=share" target="_blank" class="button">YOUTUBE LIVE</a>
        <a href="https://chat.whatsapp.com/EC52uk8lTS4J1d7274Ace2" target="_blank" class="button"> WhatsApp Community</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/links.scss';
</style>