<template>
  <section class="speakers guests">
    <div class="speakers__content container">
      <h1 class="speakers__title">
        Guests
      </h1>
      <div class="speakers__cards">
        <div class="speakers__cards--single" v-for="guest in guests" :key="guest.id">
          <div class="speaker-btn">
            <img :src="getImagePath(guest.image)" :alt="guest.name">
            <h2>{{ guest.name }}</h2>
            <h4>{{ guest.title }}</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue';
import guestsData from './json/guests.json';

export default {
  name: 'Guests',
  setup() {
    const guests = ref(guestsData);
    function getImagePath(imagePath) {
      return require(`@/assets/guests/${imagePath}`);
    }

    return { guests, getImagePath };
  }
};
</script>

<style lang="scss" scoped>
  @import '@/styles/components/speakers.scss';
</style>
