<template>
  <section class="hotel">
    <div class="hotel__content">
      <div class="hotel__info container">
        <p class="hotel__description">
          Mark your calendars for May 29, 2024! The stage is set, and the anticipation is mounting for the
          Asia Investor Summit - Hong Kong, an event that promises to redefine industry standards and ignite
          new pathways in the world of finance. Nestled in the heart of Hong Kong, amidst the breathtaking
          backdrop of the Diamond Ballroom at The Ritz-Carlton, ICC, this summit is poised to be an unparalleled
          convergence of brilliance and innovation.
        </p>
        <p class="hotel__description">
          Prepare to immerse yourself in a day filled with enlightenment, collaboration, and opportunity. Our esteemed speakers,
          handpicked for their expertise and vision, will illuminate the latest trends, insights, and strategies
          shaping the financial landscape. From seasoned veterans to trailblazing entrepreneurs, our lineup boasts
          individuals who are at the forefront of driving change and charting the course for tomorrow's successes
        </p>
        <generalformcta buttonText="Register Here" />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/hotel.scss';
</style>

<script>
import { defineComponent } from 'vue';
import generalformcta from '@/components/generalformcta.vue';

export default defineComponent({
  name: 'Hotel Info',
  components: {
    generalformcta
  },
  data() {
    return {
    };
  },
});

</script>