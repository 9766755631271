<template>
  <section class="services">
    <div class="services__butler">
      <div class="services__content container">
        <div class="services__content--info">
          <h1 class="services__title">
            The Lounge & Bar
          </h1>
          <p class="services__description">
            The Lounge & Bar is an excellent all day dining venue with Victoria Harbour view,
            the restaurant serves breakfast buffet, organic salad bar, à la carte menu,
            afternoon tea and cocktails; a perfect setting for all-day business, leisure, & romantic dining.
          </p>
          <a href="https://www.ritzcarlton.com/en/hotels/hkgkw-the-ritz-carlton-hong-kong/overview/?nst=paid&cid=PAI_GLB00050K5_GLE000C53T_GLF000QGQD&ppc=ppc&pId=corpimedia&gad_source=1&gclid=Cj0KCQjw2uiwBhCXARIsACMvIU1OuWNOFFblCukHb546gn-I6sOyPTgaYzp4u2w4_JbxV58F0yqUDZoaAqkdEALw_wcB&gclsrc=aw.ds"
              target="_blank" class="services__btn btn">
            <button class="button">Learn More</button>
          </a>
        </div>
        <div class="services__content--image">
          <img src="@/assets/venue/bar.png" alt="Lounge & Bar">
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: 'Nightlife',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/components/venue/services.scss";
</style>