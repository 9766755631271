<template>
    <section class="location">
      <div class="location__banner">
        <div class="location__banner--content container">
          <h1 class="location__title">
            Diamond Ballroom, The Ritz-Carlton, ICC Hong Kong
          </h1>
          <h2 class="location__subtitle">
            May 29, 2024
          </h2>
          <h3 class="location__description">
            Book your Hotel room
          </h3>
          <div class="location__btn">
            <div class="location__single">
              <img src="@/assets/location/ritz.png" alt="Ritz Hotel Logo">
              <a href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1712834378132&key=GRP&app=resvlink" target="_blank" class="company-btn btn">
                <button class="button">The Ritz-Carlton</button>
              </a>
            </div>
            <div class="location__single">
              <img src="@/assets/location/mp.png" alt="Marco Polo Hotel Logo">
              <a href="https://bookings.marcopolohotels.com/landing?adult=1&arrive=2024-05-24&chain=22789&child=0&config=marcopolo_brand&configcode=marcopolo_brand&currency=HKD&depart=2024-05-25&hotel=3391&level=hotel&locale=en-US&promo=Solowin&rooms=1" target="_blank" class="company-btn btn">
                <button class="button">Marco Polo Hotel</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <style lang="scss" scoped>
    @import '@/styles/components/location.scss';
  </style>
  
  <script>

  export default {
    name: 'location',
    components: {
      
    },
    data() {
      return {
      };
    },
  };
  </script>