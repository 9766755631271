<template>
  <div class="agenda__content container">
    <div class="agenda__date">
      <div class="date__container">
        <div>
          <h1 class="date__title">
            Wednesday, May 29, 2024
          </h1>
        </div>
      </div>
      <hr class="date__line">
    </div>
    <h2 class="subtitle">
      Lunch
    </h2>
    <div class="cards__container">
      <div class="cards__single grayblue noheight">
        <p class="cards__title">
          Buffet Lunch
        </p>
        <p class="cards__time">
          11:30 AM - 1:00 PM
        </p>
        <div class="cards__description">
          <p>
            Registration and Buffet lunch will be served for attendees. Informal networking opportunity.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '@/styles/pages/agenda.scss';
</style>
