<template>
  <Menu />
  <main id="venue">
    <welcome />
    <stay />
    <services />
    <experience />
    <dining />
    <nightlife />
    <ceremony />
  </main>
</template>

<style lang="scss" scoped>
  @import '../styles/pages/home.scss';
</style>

<script>
import Menu from '@/components/menu';
import welcome from '@/components/venue/welcome.vue';
import stay from '@/components/venue/stay.vue';
import services from '@/components/venue/services.vue';
import dining from '@/components/venue/dining.vue';
import nightlife from '@/components/venue/nightlife.vue';
import experience from '@/components/venue/experience.vue';
import ceremony from '@/components/ceremony.vue';

export default {
  name: 'Venue',
  data() {
    return {
    };
  },
  components: {
    Menu,
    welcome,
    stay,
    services,
    dining,
    nightlife,
    experience,
    ceremony
  },
};
</script>
