<template>
  <section class="stay">
    <div class="stay__content container">
      <div class="stay__content--image">
        <img src="@/assets/venue/carousel/spa.png" alt="Spa service">
      </div>
      <div class="stay__content--info">
        <h1 class="stay__title">
          The Ritz-Carlton Spa, Hong Kong
        </h1>
        <p class="stay__description">
          The Ritz-Carlton Spa. Hong Kong re-defines the urban spa experience. With 11 treatment rooms,
          offering floor-to-ceiling windows with panoramic harbour views where a world-class
          team of professional therapists ensure a spa experience like no other.
        </p>
        <a href="https://www.ritzcarlton.com/en/hotels/hkgkw-the-ritz-carlton-hong-kong/overview/?nst=paid&cid=PAI_GLB00050K5_GLE000C53T_GLF000QGQD&ppc=ppc&pId=corpimedia&gad_source=1&gclid=Cj0KCQjw2uiwBhCXARIsACMvIU1OuWNOFFblCukHb546gn-I6sOyPTgaYzp4u2w4_JbxV58F0yqUDZoaAqkdEALw_wcB&gclsrc=aw.ds"
          target="_blank" class="stay__btn btn">
          <button class="button">Learn More</button>
        </a>
      </div>
    </div>
  </section>
</template>


<script>

export default {
  name: 'Stay',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/components/venue/stay.scss";
</style>