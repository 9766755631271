<template>
  <section class="hotel">
    <div class="hotel__content">
      <div class="hotel__cards">
        <div class="hotel__card--content container">
            <div class="card">
                <img src="../assets/location/Icon2.svg" alt="Attendees Icon">
                <h3 class="card__title">
                  Insightful Keynote Sessions:
                </h3>
                <p class="card__description">
                  Gain profound insights into the financial industry's dynamics with captivating keynotes by
                  Jon Najarian and Brock Pierce. Discover current trends, challenges, and emerging opportunities,
                  charting a course towards financial success and innovation.
                </p>
            </div>
            <div class="card">
                <img src="../assets/location/Icon3.svg" alt="Investors Icon">
                <h3 class="card__title">
                  Dynamic Panel Discussions:
                </h3>
                <p class="card__description">
                  Engage in thought-provoking discussions on pivotal topics such as "Asian Based Companies Listing on Hong Kong and US Exchanges"
                  and "The Future of Fintech in Asia." Uncover the strategies, regulations,
                  and innovations shaping the future of finance, led by industry luminaries and regulatory experts.
                </p>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/hotel.scss';
</style>

<script>
export default ({
  name: 'Hotel Cards',
  data() {
    return {
    };
  },
});

</script>