<template>
  <section class="speakers">
    <div class="speakers__content container">
      <h1 class="speakers__title">
        Featured Speakers
      </h1>
      <div class="speakers__cards">
        <div class="speakers__cards--single"
          v-for="speaker in speakers"
          :key="speaker.id"
          :class="{ 'special-speaker': speaker.id === specialSpeakerId }">
          <button @click="toggleModal(speaker.id)" class="speaker-btn">
            <img :src="getImagePath(speaker.image)" :alt="speaker.name">
            <h2>{{ speaker.name }}</h2>
            <h4 v-html="speaker.title"></h4>
          </button> 
          <transition name="fade" appear>
            <div class="modal-overlay" v-if="modals[speaker.id]" @click="toggleModal(speaker.id)"></div>
          </transition>
          <transition name="pop" appear>
            <div class="modal" role="dialog" v-if="modals[speaker.id]">
              <div class="speaker-modal">
                <div class="speaker-thumbnail">
                  <img :src="getImagePath(speaker.image)" :alt="speaker.name">
                </div>
                <div class="speaker-info">
                  <h2>{{ speaker.name }}</h2>
                  <h3 v-html="speaker.title"></h3>
                  <p v-html="speaker.bio"></p>
                </div>
              </div>
              <button @click="toggleModal(speaker.id)" class="close">⨉</button>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue';
import speakersData from './json/speakers.json';

export default {
  name: 'Speakers',
  setup() {
    const speakers = ref(speakersData);
    const modals = ref({});

    speakers.value.forEach(speaker => {
      modals.value[speaker.id] = false;
    });

    function toggleModal(id) {
      modals.value[id] = !modals.value[id];
    }

    function getImagePath(imagePath) {
      return require(`@/assets/speakers/${imagePath}`);
    }

    return { speakers, modals, toggleModal, getImagePath, specialSpeakerId: 'chan' };
  }
};
</script>

<style lang="scss" scoped>
  @import '@/styles/components/speakers.scss';

  .special-speaker {
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    button {
      width: 290px;
      @include upto ($breakpoint_sm) {
        width: 270px;
      }
    }
  }
</style>
