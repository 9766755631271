<template>
  <section class="welcome ceremony">
    <div class="welcome__content container">
      <div class="welcome__content--headline">
        <h1 class="welcome__title">
          Solowin's Listed Ceremony
        </h1>
      </div>
    </div>
    <div class="welcome__content--slider">
      <Carousel :items-to-show="computedItemsToShow" :wrap-around="true" v-model="currentSlide">
        <Slide v-for="(image, index) in images" :key="index">
          <div class="carousel__item">
            <img :src="image" :alt="'Image ' + index">
          </div>
        </Slide>
        <template #addons>
          <Pagination />
          <Navigation />
        </template>
      </Carousel>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Navigation, Slide, Pagination } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'Venue Welcome',
  data() {
    return {
      images: [
        require('@/assets/ceremony/4.png'),
        require('@/assets/ceremony/5.png'),
        require('@/assets/ceremony/6.png'),
        require('@/assets/ceremony/7.png'),
        require('@/assets/ceremony/8.png'),
        require('@/assets/ceremony/9.png'),
        require('@/assets/ceremony/10.png'),
        require('@/assets/ceremony/11.png'),
        require('@/assets/ceremony/12.png'),
        require('@/assets/ceremony/13.png'),
        require('@/assets/ceremony/14.png'),
        require('@/assets/ceremony/1.png'),
        require('@/assets/ceremony/2.png'),
        require('@/assets/ceremony/3.png')
      ],
      currentSlide: 0,
    };
  },
  components: {
    Carousel,
    Slide,
    Navigation,
    Pagination
  },
  computed: {
    computedItemsToShow() {
      const width = window.innerWidth;
      if (width < 900) {
        return 1;
      } else if (width >= 900 && width < 1300) {
        return 2;
      } else {
        return 2.3;
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.$forceUpdate();
    }
  }
});

</script>

<style lang="scss" scoped>
@import "@/styles/components/venue/welcome.scss";

.ceremony {
  padding: 50px 0 100px 0;
  border-bottom: 0;
  @include upto ($breakpoint_md) {
    padding: 20px 0 100px 0;
  }
}
</style>

<style lang="scss">
@import '@/styles/import/variables.scss';
@import '@/styles/import/mixins.scss';

.carousel__prev,
.carousel__next {
  background-color: #fff;
  border-radius: 50%;
  &:hover {
    color: #000;
  }
}
.carousel__pagination-button::after {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgb(255 255 255 / 15%);
}

.carousel__pagination-button--active::after,
.carousel__pagination-button--active:hover::after {
  background-color: #fff !important;
}

.carousel__pagination-button:hover::after{
  background-color: rgb(255 255 255 / 50%);
}

.carousel__slide > .carousel__item > img {
  opacity: .2;
  border-radius: 30px;
}

.carousel__slide--active.carousel__slide--visible > .carousel__item {
  border-radius: 30px;
  img {
    opacity: 1;
    border-radius: 30px;
  }
}
.carousel__pagination {
  margin: 20px 0 0;
  padding: 0;

}
</style>