<template>
  <section class="experience">
    <div class="experience__content">
      <div class="experience__content--info">
        <h1 class="experience__title">
          Café 103
        </h1>
        <p class="experience__description">
          At Café 103, named for its setting on the hotel's 103rd floor, the refined lounge is known
          for its lunch and dinner buffets featuring local specialties and international favorites.
          <br><br>
          Everyday:<br>
          12:00 PM-10:00 PM<br>
          Dress Code:<br>
          Casual
        </p>
        <a href="https://www.ritzcarlton.com/en/hotels/hkgkw-the-ritz-carlton-hong-kong/overview/?nst=paid&cid=PAI_GLB00050K5_GLE000C53T_GLF000QGQD&ppc=ppc&pId=corpimedia&gad_source=1&gclid=Cj0KCQjw2uiwBhCXARIsACMvIU1OuWNOFFblCukHb546gn-I6sOyPTgaYzp4u2w4_JbxV58F0yqUDZoaAqkdEALw_wcB&gclsrc=aw.ds"
           target="_blank" class="stay__btn btn">
          <button class="button">Learn More</button>
        </a>
      </div>
      <div class="experience__content--image">
        <img src="@/assets/venue/food.jpeg" alt="Cafe 103">
      </div>
    </div>
  </section>
</template>


<script>

export default {
  name: 'Condado Vanderbilt Experience',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/components/venue/experience.scss";
</style>