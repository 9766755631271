<template>
  <section class="dining">
    <div class="dining__fine">
      <div class="dining__content container">
        <div class="dining__content--column">
          <img src="@/assets/venue/tosca.png" class="restaurant" alt="Tosca Di Angelo">
          <h1 class="dining__title">
            Tosca Di Angelo
          </h1>
          <p class="dining__description">
            Experience elegant Italian fine dining with high ceilings, fountains, chandeliers,
            and stunning views at Tosca di Angelo. Dress code: Gentlemen should avoid beach sandals,
            open-toed shoes, sleeveless shirts, or shorts.
          </p>
          <a href="https://www.ritzcarlton.com/en/hotels/hkgkw-the-ritz-carlton-hong-kong/overview/?nst=paid&cid=PAI_GLB00050K5_GLE000C53T_GLF000QGQD&ppc=ppc&pId=corpimedia&gad_source=1&gclid=Cj0KCQjw2uiwBhCXARIsACMvIU1OuWNOFFblCukHb546gn-I6sOyPTgaYzp4u2w4_JbxV58F0yqUDZoaAqkdEALw_wcB&gclsrc=aw.ds"
              target="_blank" class="dining__btn btn">
            <button class="button">Learn More</button>
          </a>
        </div>
        <div class="dining__content--column">
          <img src="@/assets/venue/lung.png" class="restaurant" alt="Tin Lung Heen">
          <h1 class="dining__title">
            Tin Lung Heen
          </h1>
          <p class="dining__description">
            Savor refined Cantonese cuisine, traditional Dim Sum, and premium tea at Tin Lung Heen.
            Dress code: Gentlemen should avoid beach sandals, open-toed shoes, sleeveless shirts, or shorts.
          </p>
          <a href="https://www.ritzcarlton.com/en/hotels/hkgkw-the-ritz-carlton-hong-kong/overview/?nst=paid&cid=PAI_GLB00050K5_GLE000C53T_GLF000QGQD&ppc=ppc&pId=corpimedia&gad_source=1&gclid=Cj0KCQjw2uiwBhCXARIsACMvIU1OuWNOFFblCukHb546gn-I6sOyPTgaYzp4u2w4_JbxV58F0yqUDZoaAqkdEALw_wcB&gclsrc=aw.ds"
              target="_blank" class="dining__btn btn">
            <button class="button">Learn More</button>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>


<script>

export default {
  name: 'dining',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/components/venue/dining.scss";
</style>